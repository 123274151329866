<template>
  <div>
    <h1 class="text-h4">Управление товарами</h1>
    <v-divider class="my-2"></v-divider>
    <v-form v-model="form.valid" :disabled="busy" @submit.prevent="submitForm">
      <v-card>
        <v-card-title>Yandex market {{ item.code }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="form.data.name"
            label="Название (name)"
            counter="150"
          />
          <v-text-field
            v-model="form.data.typePrefix"
            label="Товарный префикс (typePrefix)"
            counter="150"
          />
          <v-text-field
            v-model="form.data.vendor"
            label="Вендор (vendor)"
            counter="150"
          />
          <v-text-field
            v-model="form.data.model"
            label="Модель (model)"
            counter="150"
          />
          <v-textarea
            v-model="form.data.description"
            label="Описание (description)"
            counter="1024"
          ></v-textarea>
          <v-textarea
            v-model="form.data.shortDescription"
            label="Короткое описание (shortDescription)"
            counter="250"
          ></v-textarea>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="$router.back()">Назад</v-btn>
          <!--          <v-btn :to="{name: 'item.view', params: { id }}">Отмена</v-btn>-->
          <v-btn color="primary" :disabled="!form.valid" type="submit">Отправить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { ruleNumeric, ruleRequired } from '@/utils/FormRules'
import { mapActions, mapState } from 'vuex'
import ItemUpdateForm from '@/model/item/ItemUpdateForm'
import ItemYandexMarketUpdateForm from '@/model/item/ItemYandexMarketUpdateForm'

export default {
  ...dashboardPageCommonAttributes,
  name: 'ItemEditPage',
  props: {
    id: {
      type: [String],
      required: true
    }
  },
  data() {
    return {
      form: {
        valid: true,
        data: {
          name: null,
          typePrefix: null,
          vendor: null,
          model: null,
          description: null,
          shortDescription: null
        },
        rule: {
        }
      }
    }
  },
  computed: {
    ...mapState('item_yandex_market_edit', ['item', 'busy'])
  },
  created() {
    this.fetchData({ id: this.id })
      .then(() => {
        const ym = this.item.yandexMarket

        this.form.data.name = ym.name
        this.form.data.typePrefix = ym.typePrefix
        this.form.data.vendor = ym.vendor
        this.form.data.model = ym.model
        this.form.data.description = ym.description
        this.form.data.shortDescription = ym.shortDescription
      })
  },
  methods: {
    ...mapActions('item_yandex_market_edit', ['fetchData', 'updateItem']),
    submitForm() {
      const form = ItemYandexMarketUpdateForm.create(this.form.data)

      this.updateItem({ form })
        .then((id) => this.$router.back())
        // .then((id) => this.$router.push({ name: 'item.view', params: { id } }))
    }
  }
}
</script>
