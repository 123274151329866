export default class ItemYandexMarketUpdateForm {
  constructor(data) {
    this.isActive = data?.isActive
    this.name = data?.name
    this.typePrefix = data?.typePrefix
    this.vendor = data?.vendor
    this.model = data?.model
    this.description = data?.description
    this.shortDescription = data?.shortDescription
  }

  static create(data) {
    return new ItemYandexMarketUpdateForm(data)
  }

}
